import React, { useEffect, useState } from "react";
import { Candle } from "./StockChartTile";
import StockChartTile from "./StockChartTile";
import { POSITIVE_SENTIMENT, NEGATIVE_SENTIMENT } from "../App";


export interface SentimentData {
  positive: number;
  neutral: number;
  negative: number;
}

// Interface for recommendations data
export interface RecommendationsData {
  overbought: number;
  oversold: number;
  neutral: number;
}

// Interface for sector details
export interface SectorDetails {
  positive: number;
  neutral: number;
  negative: number;
  total: number;
  net: number;
}

export interface SectorsData {
  [key: string]: SectorDetails;
}

export interface MarketData {
  sentiment?: SentimentData;
  recommendations?: RecommendationsData;
  sectors?: SectorsData;
}

export interface Pivot {
  date: string,
  popularity: number
}


export interface StockData {
  symbol: string;
  date: string;
  price: string;
  current_price: string;
  isMature: boolean;
  sentiment: Sentiment;
  secSentimentScore: number;
  data: string[];
  current_recommendation: string;
  tsx: string;
  isSelected: boolean;
  current_rsi: string;
  days_until_earnings: number;
  days_since_earnings: number;
  eps_sentiment: string;
  candles: Candle[];
  isRecent: boolean;
  spy_correlation: number;
  fundamentalsScore: Fundamentals;
  regression_score: number;
  regression_slope: number;
  today_probability: number;
  tomorrow_probability: number;
  last_30_days_return: number;
  last_10_days_return: number;
  last_1_day_return: number;
  popularity_pivots: {
    peaks: Pivot[]
    bottoms: Pivot[]
  }
  resilience_index: number;
  company_name: string;
}

interface Sentiment {
  sentimentScore: number;
  challenges: string[];
}

interface Fundamentals {
  income_statement_score: number;
  balance_sheet_score: number;
  cash_flow_score: number;
  challenges: string[];
  overall_score: number;
  earnings_score: number;
}

interface Props {
  data: StockData[];
  onItemClick: (selectedItem: StockData) => void;
  onCheckboxClick: (selectedItem: StockData, checked: boolean) => void;
}

const StockDataDisplay: React.FC<Props> = ({
  data,
  onItemClick,
  onCheckboxClick,
}) => {
  const [hoveredSymbol, setHoveredSymbol] = useState<string | null>(null);

  const handleCheckboxClick = (event: any, stock: StockData) => {
    const checked = event.target.checked;
    onCheckboxClick(stock, checked); // Notify the parent component with the selected item
  };

  const handleItemClick = (stock: StockData) => {
    onItemClick(stock); // Notify the parent component with the selected item
  };

  const handleMouseEnter = (symbol: string) => {
    setHoveredSymbol(symbol);
  };

  const handleMouseLeave = () => {
    setHoveredSymbol(null);
  };

  return (
    <div className="stockdata-container">
        {data.map((stock) => {
          const isPositive = stock.sentiment?.sentimentScore >= POSITIVE_SENTIMENT;
          const isNegative = stock.sentiment?.sentimentScore < NEGATIVE_SENTIMENT;
          const isNeutral = !isPositive && !isNegative;
          const isMature = stock.regression_score > 0;

          const priceColor =
            hoveredSymbol === stock.symbol
              ? "var(--color-hovered)"
              : isPositive
              ? "var(--color-positive)"
              : isNegative
              ? "var(--color-negative)"
              : isNeutral
              ? "var(--color-neutral)"
              : "var(--color-transparent)";

          const tileBackgroundColor = isMature ? "var(--color-mature)" : priceColor;

          return (
            <div
              className="stock-tile-container"
              key={stock.symbol}
              style={{
                backgroundColor: tileBackgroundColor,
              }}
              onMouseEnter={() => handleMouseEnter(stock.symbol)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="stock-tile-symbol-row">
                <div className="stock-tile-symbol-column-left">
                  <div>
                    <div className="stock-tile-checkbox">
                      <input
                        checked={stock.isSelected}
                        type="checkbox"
                        onChange={(event) => handleCheckboxClick(event, stock)}
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                  </div>
                  <div className="stock-tile-symbol-label">{stock.symbol}</div>
                  <div className="stock-tile-company-name-label">{stock.company_name}</div>
                </div>
                <div className="stock-tile-symbol-column-right">
                  <div className="stock-tile-date-label">{stock.date}</div>
                  <div className="stock-tile-trends-label">📰 {stock.popularity_pivots?.peaks.at(-1)?.date} ({stock.popularity_pivots?.peaks.at(-1)?.popularity})</div>
                </div>
              </div>
              <div className="stock-tile-chart">
                <StockChartTile data={stock.candles} />
              </div>

              <div onClick={() => handleItemClick(stock)}>
                <div className="stock-tile-emojis">
                  {stock.regression_slope > 0 ? "📈" : ""}
                  {stock.regression_score < 0
                    ? "💰".repeat(Math.abs(stock.regression_score))
                    : ""}
                  {stock.regression_score > 0
                    ? "🔥".repeat(Math.abs(stock.regression_score))
                    : ""}
                  {/* {stock.sentiment.optimism > 5 ? "😃" : ""} */}
                  {/* {stock.spy_correlation < 0.25 ? "α" : ""} */}
                  {stock.secSentimentScore * 100 > 50 ? "👔" : ""}
                  {stock.fundamentalsScore?.overall_score > 7 ? "💵" : ""}
                  {stock.eps_sentiment === "positive" ? "📝" : ""}
                </div>
                <div className="stock-tile-price-row">
                  <div className="stock-tile-price-column-left">
                    <div className="stock-tile-price-entry-label">Entry</div>
                    <div className="stock-tile-price-price-label">{`$${stock.price}`}</div>
                  </div>
                  <div className="stock-tile-price-column-right">
                    <div className="stock-tile-price-current-label">
                      Current
                    </div>
                    <div className="stock-tile-price-current-price-label">{`$${stock.current_price}`}</div>
                  </div>
                </div>

                <div className="stock-tile-stats-row">
                  <div className="stock-tile-stats-correlation-column">
                    <div className="stock-tile-stats-correlation-label">
                      SPY Corr.
                    </div>
                    <div className="stock-tile-stats-correlation-value">{`${Math.round(
                      stock.spy_correlation * 100
                    )}%`}</div>
                  </div>
                  <div className="stock-tile-stats-sec-column">
                    <div className="stock-tile-stats-sec-label">SEC</div>
                    <div className="stock-tile-stats-sec-value">{`${Math.round(
                      stock.secSentimentScore * 100
                    )}%`}</div>
                  </div>

                  {stock.days_since_earnings && (
                    <div className="stock-tile-stats-earnings-column">
                      <div className="stock-tile-stats-earnings-label">
                        Cicle:
                      </div>
                      <div className="stock-tile-stats-earnings-value">
                        {Math.round(
                          (stock.days_since_earnings /
                            (stock.days_until_earnings +
                              stock.days_since_earnings)) *
                            100
                        )}
                        %
                      </div>
                    </div>
                  )}
                </div>

                <br></br>

                <div className="stock-tile-stats-row">
                  <div className="stock-tile-stats-correlation-column">
                    <div className="stock-tile-stats-correlation-label">
                      Today's Prob.
                    </div>
                    <div className="stock-tile-stats-correlation-value">{`${Math.round(
                      stock.today_probability * 100
                    )}%`}</div>
                  </div>
                  <div className="stock-tile-stats-sec-column">
                    <div className="stock-tile-stats-sec-label">Tomorrow's Prob.</div>
                    <div className="stock-tile-stats-sec-value">{`${Math.round(
                      stock.tomorrow_probability * 100
                    )}%`}</div>
                  </div>
                  <div className="stock-tile-stats-sec-column">
                    <div className="stock-tile-stats-sec-label">Resilience</div>
                    <div className="stock-tile-stats-sec-value">{`${Math.round(
                      stock?.resilience_index
                    )}%`}</div>
                  </div>
                </div>

                <br></br>

                <div className="stock-tile-stats-row">
                  <div className="stock-tile-stats-eps-column">
                    <div className="stock-tile-stats-eps-label">
                    EPS
                    </div>
                    <div className="stock-tile-stats-eps-value">{stock.eps_sentiment}</div>
                  </div>
                  <div className="stock-tile-stats-eps-column">
                    <div className="stock-tile-stats-eps-label">
                    Yeld (30d/10d/1d)
                    </div>
                    <div className="stock-tile-stats-eps-value">{`${Math.round(
                      stock.last_30_days_return * 100
                    )}%`} / {`${Math.round(
                      stock.last_10_days_return * 100
                    )}%`} / {`${Math.round(
                      stock.last_1_day_return * 100
                    )}%`}</div>
                  </div>
                </div>

                {/* {stock.fundamentalsScore && (
                <div>
              <div style={dateStyle}>Fundamentals: {stock.fundamentalsScore.overall_score}</div>
              <div style={dateStyle}>Earnings: {stock.fundamentalsScore.earnings_score}</div>
              </div>
              )} */}
                {stock.sentiment?.sentimentScore && (
                  <div>
                    <div className="stock-tile-sentiment-score">
                      Sentiment: {stock.sentiment?.sentimentScore}
                    </div>
                    <div className="stock-tile-sentiment-keywords">
                      {stock.sentiment?.challenges.map((challenge, index) => (
                        <div
                          key={index}
                          className="stock-tile-sentiment-challenge"
                        >
                          {challenge}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default StockDataDisplay;
